exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aoe-2-buildorderviewer-js": () => import("./../../../src/pages/aoe2buildorderviewer.js" /* webpackChunkName: "component---src-pages-aoe-2-buildorderviewer-js" */),
  "component---src-pages-booking-system-js": () => import("./../../../src/pages/booking-system.js" /* webpackChunkName: "component---src-pages-booking-system-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-policy-paynal-reservaciones-js": () => import("./../../../src/pages/policy/paynal-reservaciones.js" /* webpackChunkName: "component---src-pages-policy-paynal-reservaciones-js" */),
  "component---src-pages-policy-pronouncepal-js": () => import("./../../../src/pages/policy/pronouncepal.js" /* webpackChunkName: "component---src-pages-policy-pronouncepal-js" */),
  "component---src-pages-policy-walletsage-js": () => import("./../../../src/pages/policy/walletsage.js" /* webpackChunkName: "component---src-pages-policy-walletsage-js" */)
}

